import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { NumberSelectOption, SelectOption } from 'src/app/models/common/angular-material-ui/select-option.interface';

@Component({
  selector: 'app-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFormFieldComponent),
      multi: true,
    },
  ],
})
export class SelectFormFieldComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() multiple = true;
  @Input() hideSelectAllOption = false;
  @Input() label: string;
  @Input() labelPosition: 'top' | 'inside' = 'inside';
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() searchPlaceholder: string;
  @Input() options: SelectOption[] | NumberSelectOption[] = [];
  @Input() appearance: MatFormFieldAppearance = 'outline';

  values: string[] = [];
  private hasNumberOptions: boolean;
  optionsWithStringValues: SelectOption[] = [];
  searchedOptions: SelectOption[] | undefined = undefined;
  selectAllState = { isChecked: false, isIndeterminate: false };

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.hasNumberOptions = typeof this.options[0]?.value === 'number';
      this.optionsWithStringValues = !this.hasNumberOptions
        ? this.options as SelectOption[]
        : this.options.map((option: SelectOption | NumberSelectOption) => ({ ...option, value: String(option.value) }));
    }
  }

  propagateTouched: () => void = () => {};
  private propagateChange: (value: string | string[]) => void = () => {};

  handleSearchInputChange(searchValue: string): void {
    const lowerCaseSearchValue = searchValue.trim().toLowerCase();
    this.searchedOptions = !lowerCaseSearchValue
      ? undefined
      : this.optionsWithStringValues.filter(option => option.label.toLowerCase().includes(lowerCaseSearchValue));
    this.updateSelectAllState();
  }

  writeValue(newValue: string | string[] | number | number[]): void {
    const newValueAsArray = Array.isArray(newValue) ? newValue.map(String) : newValue || newValue === 0 ? [String(newValue)] : [];
    const searchedOptionsValues = this.searchedOptions?.map(option => option.value);
    const nonSearchedValues = searchedOptionsValues ? this.values.filter(value => !searchedOptionsValues.includes(value)) : [];
    this.values = [...nonSearchedValues, ...newValueAsArray];
  }

  handleSelectChange(value: string | string[]): void {
    this.writeValue(value);
    this.propagateChange(value);
    this.updateSelectAllState();
  }

  private updateSelectAllState(): void {
    const { isChecked, isIndeterminate } = this.getSelectAllCheckboxState();
    this.selectAllState = { isChecked, isIndeterminate };
  }

  handleSelectAllChange(): void {
    const { onSelectAllNewValue } = this.getSelectAllCheckboxState();
    this.handleSelectChange(onSelectAllNewValue);
  }

  registerOnChange(fn: (value: string | string[] | number | number[]) => void): void {
    this.propagateChange = (value: string | string[]) => {
      const formattedValue = !this.hasNumberOptions ? value : Array.isArray(value) ? value.map(Number) : Number(value);
      fn(formattedValue);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private getSelectAllCheckboxState(): {
    isChecked: boolean,
    isIndeterminate: boolean,
    onSelectAllNewValue: string[]
  } {
    const displayedOptions = this.searchedOptions ?? this.optionsWithStringValues;
    const displayedOptionsValues = displayedOptions.map(option => option.value);

    const isAllChecked = displayedOptionsValues.every((value) => this.values.includes(value));
    const isAnyValueChecked = displayedOptionsValues.some((value) => this.values.includes(value));
    const valuesNotFromOptions = this.values.filter((value) => !displayedOptionsValues.includes(value));

    const isChecked = isAnyValueChecked && isAllChecked;
    const isIndeterminate = isAnyValueChecked && !isAllChecked;
    const onSelectAllNewValue = [...valuesNotFromOptions, ...(isAllChecked ? [] : displayedOptionsValues)];

    return { isChecked, isIndeterminate, onSelectAllNewValue };
  }
}
