import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

export type TRangePickerDate = {
  startDate: Date
  endDate: Date
};

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent)
    }
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor, AfterViewInit {

  @Input() startDate: string;

  @Input() endDate: string;

  @Input() isDisabled = false;

  @Input() placeholderStartDate = 'Data início';

  @Input() placeholderEndDate = 'Data fim';

  @Input() min: Date;

  @Input() max: Date;

  @ViewChild('picker', { static: false }) picker: MatDateRangePicker<any>;

  public dateGroup: FormGroup;

  private onTouched!: Function;

  private onChanged!: Function;

  constructor(private adapter: DateAdapter<any>) {
    this.adapter.setLocale('pt-BR');
    this.dateGroup = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl()
    });
  }

  ngAfterViewInit(): void {
    this.picker.closedStream.subscribe(x => {
      const startDate = this.dateGroup.controls.startDate.value;
      if (startDate instanceof Date && !this.dateGroup.controls.endDate.value) {
        this.onTouched();

        const endDate = new Date(startDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        this.dateGroup.controls.endDate.setValue(endDate);
        this.onChanged({ startDate, endDate });
      }
    });
  }

  reset(): void {
    this.dateGroup.reset();
    this.onChanged();
  }

  handleChange(): void {
    if (this.dateGroup.controls?.startDate?.value && this.dateGroup.controls?.endDate?.value) {
      this.onTouched();

      this.dateGroup.controls?.startDate?.value?.setHours(0, 0, 0, 0);
      this.dateGroup.controls?.endDate?.value?.setHours(23, 59, 59, 999);

      this.onChanged({
        startDate: this.dateGroup.controls?.startDate?.value || null,
        endDate: this.dateGroup.controls?.endDate?.value || null
      });
    }
  }

  writeValue(range: TRangePickerDate): void {
    if (!range) {
      this.dateGroup.reset();
    } else {
      this.dateGroup = new FormGroup({
        startDate: new FormControl(range?.startDate),
        endDate: new FormControl(range?.endDate)
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
