<mat-label *ngIf="label && labelPosition === 'top'">{{ label }}</mat-label>

<mat-form-field [appearance]="appearance" style="width: 100%">
  <mat-label *ngIf="label && labelPosition === 'inside'">{{ label }}</mat-label>

  <mat-select
    [multiple]="multiple"
    [disabled]="disabled"
    [value]="multiple ? values : values[0]"
    [placeholder]="placeholder"
    (blur)="propagateTouched()"
    (selectionChange)="handleSelectChange($event.value)"
    (openedChange)="$event ? inputElement.focus() : inputElement.value = ''; handleSearchInputChange(inputElement.value)"
  >
    <div class="select-search-input-wrapper">
      <input
        matInput
        #inputElement
        class="select-search-input"
        [placeholder]="searchPlaceholder ?? ('FILTER.DEFAULT_SEARCH_PLACEHOLDER' | translate)"
        (input)="handleSearchInputChange(inputElement.value)"
      />
      <mat-icon
        matSuffix
        aria-label="Clear search"
        class="clear-search-icon"
        *ngIf="!!inputElement.value"
        (click)="handleSearchInputChange(''); inputElement.value = ''"
      >
        close
      </mat-icon>
    </div>

    <mat-checkbox
      class="mat-option select-all-toggle"
      color="primary"
      *ngIf="multiple && !hideSelectAllOption"
      [checked]="selectAllState.isChecked"
      [indeterminate]="selectAllState.isIndeterminate"
      (change)="handleSelectAllChange()"
    >
      Selecionar tudo
    </mat-checkbox>

    <mat-option *ngFor="let option of (searchedOptions ?? optionsWithStringValues)" [value]="option.value">
      <span class="select-option-content">
        <span class="select-option-label">{{ option.label }}</span>
        <span *ngIf="option.caption" class="select-option-caption">{{ option.caption }}</span>
      </span>
    </mat-option>
  </mat-select>

  <mat-icon
    *ngIf="!disabled && values.length > 0"
    matSuffix
    aria-label="Clear"
    (click)="$event.preventDefault(); $event.stopPropagation(); handleSelectChange(multiple ? [] : '')"
    class="clear-field-values-icon"
  >
    close
  </mat-icon>

  <mat-icon matSuffix>arrow_drop_down</mat-icon>
</mat-form-field>
