<div class="page-header">
  <a (click)="navigateBack()">
    <i class="navigate-back-icon fa-solid fa-chevron-left"></i>
  </a>
  Sinalizar diária manualmente
</div>

<div class="page-wrapper">
  <mat-card class="page-card">
    <header>
      <h2>Sinalização de diária</h2>
      <span class="priority-chip">
        <mat-icon>trending_up</mat-icon>
        <span class="priority-chip-text">Alta Prioridade</span>
      </span>
    </header>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <section class="travel-fields-section">
        <mat-form-field appearance="outline">
          <mat-label>Número da viagem</mat-label>
          <input
            matInput
            name="numeroViagem"
            formControlName="numeroViagem"
            [disabled]="isLoadingDetails || isSubmitting"
            (change)="loadDiariaManual()"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Placa do veículo</mat-label>
          <input
            matInput
            name="placa"
            formControlName="placa"
            readonly
            [disabled]="isLoadingDetails || isSubmitting"
          />
        </mat-form-field>
      </section>

      <app-diaria-details-info *ngIf="details" [details]="details"></app-diaria-details-info>

      <hr />

      <app-approval-request-form
        [isLoading]="isSubmitting"
        [formGroup]="formGroup"
        [responsibleClients]="details?.clientesResponaveis"
      ></app-approval-request-form>

      <button type="submit" class="submit mat-raised-button" [disabled]="formGroup.invalid">
        Enviar para aprovação <mat-spinner *ngIf="isSubmitting" [diameter]="20"></mat-spinner>
      </button>
    </form>
  </mat-card>

  <div class="page-cards-divider">Ou</div>

  <mat-card class="page-card">
    <app-approval-request-grid-form (submitGridForm)="submitApprovalRequestFormGrid($event)"></app-approval-request-grid-form>
  </mat-card>
</div>

<ng-template #successDialog let-data>
  <h2 mat-dialog-title class="success-dialog-title">A diária foi criada e enviada para aprovação</h2>
  <mat-dialog-content class="success-dialog-content">
    A solicitação foi enviada para aprovação para a área {{ data.responsibleArea.toLowerCase() }}.
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close class="success-dialog-close-button mat-flat-button">
      OK
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #errorDialog let-data>
  <mat-dialog-content class="error-dialog-content">
    <mat-icon class="error-dialog-content-icon">warning</mat-icon>
    <h2 class="error-dialog-content-title">{{ data.title }}</h2>
    <p class="error-dialog-content-text">{{ data.text }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close class="error-dialog-close-button mat-flat-button">
      OK
    </button>
  </mat-dialog-actions>
</ng-template>
