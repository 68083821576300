
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ApimConfig {
    subscription_portal_distribution: string;
    subscription_portal_distribution_diaria: string;
    subscription_hub_distribution: string;
    subscription_portal_primary: string;
    subscription_hub_primary: string;
    subscription_portal_agro_commodities: string;
    subscription_hub_agro_commodities: string;
    label_authorization_key: string
}
@Injectable({ providedIn: 'root' })
export class ApimService {
    private _config: BehaviorSubject<ApimConfig> = new BehaviorSubject<ApimConfig>({
        subscription_portal_distribution: "",
        subscription_portal_distribution_diaria: "",
        subscription_hub_distribution: "",
        subscription_portal_primary: "",
        subscription_hub_primary: "",
        subscription_portal_agro_commodities: "",
        subscription_hub_agro_commodities: "",
        label_authorization_key: "",
    })


    private _httpClient: HttpClient;

    constructor(
        handler: HttpBackend
    ) {
        this._httpClient = new HttpClient(handler)
        this.initConfig();
    }

    get configObs(): Observable<ApimConfig> {
        return this._config;
    }

    get config(): ApimConfig {
        return this._config.getValue();
    }

    set updateConfig(data: ApimConfig) {
        this._config.next(data);
    }

    async initConfig(): Promise<ApimConfig> {
        const result: ApimConfig = await this._httpClient.get<ApimConfig>(`${environment.express_url}/apim`).toPromise();
        this._config.next(result);
        return result;
    }
}