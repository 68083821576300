import { PointV2Size } from "../../point-v2.model";
import { ISynopticMapPointModel } from "../synoptic-map-point.interface";
import { SynopticMapPoint } from "../synoptic-map-point.model";


export class SynopticMapPointPrimary extends SynopticMapPoint<SynopticMapPointPrimary>
    implements ISynopticMapPointModel<SynopticMapPointPrimary> {

    constructor(point: any) {
        super(point);
    }

    private isAduana(): boolean {
        return this.type.toLowerCase() === 'aduana';
    }

    private getAduanaColor(): string {
        if (this.unloadingNoteDate && !this.completed) {
            return 'warning';
        }

        if (this.unloadingNoteDate && this.completed) {
            return 'ok';
        }

        return 'default';
    }

    get color(): string {
        if (this.isAduana()) {
            return this.getAduanaColor();
        }

        if (this.expectedUnloadingNoteDate <= this.unloadingNoteDate && (this.completed || this.current)) {
            return 'delay';
        }

        if (this.type === 'in_transit') {
            return 'current';
        }

        if (this.type === 'anomaly' || this.anomalies?.length > 0) {
            return 'delay';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return 'current';
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished !== null) {
            return 'ok';
        }

        return 'default';
    }

    get current(): boolean {
        if (this.type === 'anomaly') {
            return false;
        }

        if (this.type === 'in_transit') {
            return true;
        }

        if (this.unloadingNoteDate !== null && this.unloadingNoteDateFinished === null) {
            return true;
        }

        if (this.type === 'fim_rota' && this.completed) {
            return true;
        }

        return false;
    }

    private getDateWithZeroMilliseconds(dateParam: string | Date): Date {
        const date = new Date(dateParam);
        date.setMilliseconds(0);
        return date;
    }

    get date(): Date {
        if (this.isAduana() && this.unloadingNoteDate) {
            return this.getDateWithZeroMilliseconds(this.unloadingNoteDate);
        }

        if (this.unloadingNoteDateFinished) {
            return this.getDateWithZeroMilliseconds(this.unloadingNoteDateFinished);
        }

        if (this.expectedUnloadingNoteDateETA) {
            return this.getDateWithZeroMilliseconds(this.expectedUnloadingNoteDateETA);
        }

        if (this.unloadingNoteDate) {
            return this.getDateWithZeroMilliseconds(this.unloadingNoteDate);
        }

        if (this.expectedUnloadingNoteDate) {
            return this.getDateWithZeroMilliseconds(this.expectedUnloadingNoteDate);
        }

        return null;
    }

    get dateFinished(): Date {
        if (this.isAduana() && this.unloadingNoteDateFinished) {
            return this.getDateWithZeroMilliseconds(this.unloadingNoteDateFinished);
        }

        if (this.expectedUnloadingNoteDateFinished) {
            return this.getDateWithZeroMilliseconds(this.expectedUnloadingNoteDateFinished);
        }
        return null;
    }

    get urlPoint(): string {
        if (this.type === 'anomaly') {
            return `${this.anomalyUrl}/tab/1`;
        }
        return `/anomaly/rota/${this.loadNumber}/tab/3/point/${this.id}`;
    }

    get subtitle(): string {
        if (this.type === 'inicio_rota' || this.type === 'fim_rota') {
            return null;
        }

        if (this.code) {
            return `${this.code} - ${this.name}`;
        }

        return this.name;
    }

    get anomalyName(): string {
        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }

        return this.anomalies.find(anomaly => anomaly.status !== 3)?.name;
    }

    get anomalyUrl(): string {
        if (this.type === 'anomaly') {
            return `/anomaly/${this.id}/tab/1`;
        }

        if (this.anomalies === null || this.anomalies?.length === 0) {
            return null;
        }
    }

    get size(): PointV2Size {
        if (this.type === 'anomaly') {
            return PointV2Size.ExtraSmaller;
        }

        if (this.anomalies === null || this.anomalies?.length === 0 || this.anomalies?.length <= 9) {
            return this.current ? PointV2Size.Medium : PointV2Size.Smaller;
        }

        return this.current ? PointV2Size.Bigger : PointV2Size.Medium;
    }

    get title(): string {
        if (this.typePoint?.toLocaleLowerCase() === 'coleta') {
            return `Carregamento ${this.type}`;
        }
        if (this.typePoint?.toLocaleLowerCase() === 'entrega') {
            return `Descarga ${this.type}`;
        }

        if (this.typePoint) {
            return this.typePoint.toLowerCase() === this.type?.toLowerCase()
              ? this.typePoint
              : `${this.typePoint} ${this.type}`;
        }

        switch (this.type.toLowerCase()) {
            case 'armazem':
                if (this.typePoint?.toLocaleLowerCase() === 'coleta') {
                    return 'Carregamento armazém';
                }
                if (this.typePoint?.toLocaleLowerCase() === 'entrega') {
                    return 'Descarga armazém';
                }
                return 'Armazém';
            case 'wc':
                return 'Wc';
            case 'posto_fiscal':
                return 'Posto Fiscal';
            case 'manutencao':
                return 'Manutenção';
            case 'refeicao':
                return 'Refeição';
            case 'prf':
                return 'PRF';
            case 'repouso':
                return 'Repouso';
            case 'abastecimento':
                return 'Abastecimento';
            case 'aduana':
                return 'Aduana';
            case 'transito_descarga':
                return null;
            case 'transito_carregamento':
                return null;
            case 'inicio_rota':
                return 'Início da viagem';
            case 'fim_rota':
                return 'Término da viagem';
            case 'monitoramento_continuo':
                return '';
            case 'in_transit':
                return 'Em trânsito';
            default:
                return this.type;
        }


    }
}
