<ng-container [formGroup]="formGroup">
  <td class="relative-cell travel-number-cell">
    <div class="actions-row">
      <button
        type="button"
        mat-icon-button
        disableRipple
        (click)="deleteRow.emit()"
        matTooltip="Remover linha"
        matTooltipPosition="above"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <input matInput formControlName="numeroViagem" (paste)="handlePastedValue('numeroViagem', $event)" />
  </td>

  <td>
    <input matInput readonly formControlName="placa" (paste)="handlePastedValue('placa', $event)" />
  </td>

  <td class="relative-cell">
    <input matInput formControlName="areaResponsavel" readonly [value]="formGroup.get('areaResponsavel').value" />

    <select formControlName="areaResponsavel">
      <option value="" disabled *ngIf="!formGroup.get('areaResponsavel').value"></option>
      <option *ngFor="let area of responsibleAreaOptions" [value]="area.value">{{ area.label }}</option>
    </select>

    <div
      *ngIf="!!formGroup.get('areaResponsavel').value"
      class="replicate-indicator"
      (click)="emitReplicateColumnValue('areaResponsavel')"
    ></div>
  </td>

  <td class="relative-cell">
    <input
      matInput
      formControlName="motivo"
      readonly
      [value]="formGroup.get('motivo').value"
      [placeholder]="formGroup.get('areaResponsavel').value ? 'Selecione o motivo' : 'Selecione uma área responsável'"
    />

    <select formControlName="motivo" [attr.disabled]="formGroup.get('areaResponsavel').value ? undefined : true">
      <option value="" disabled *ngIf="!formGroup.get('motivo').value"></option>
      <option *ngFor="let reason of reasonsToDisplay" [value]="reason.descricao">{{ reason.descricao }}</option>
    </select>

    <div
      *ngIf="!!formGroup.get('motivo').value"
      class="replicate-indicator"
      (click)="emitReplicateColumnValue('motivo')"
    ></div>
  </td>

  <td class="relative-cell">
    <input
      matInput
      formControlName="subMotivo"
      readonly
      [value]="formGroup.get('subMotivo').value"
      [placeholder]="formGroup.get('motivo').value ? 'Selecione o submotivo' : 'Selecione um motivo'"
    />

    <select formControlName="subMotivo" [attr.disabled]="formGroup.get('motivo').value ? undefined : true">
      <option value="" disabled *ngIf="!formGroup.get('subMotivo').value"></option>
      <option *ngFor="let subReason of subReasonsToDisplay" [value]="subReason.descricao">
        {{ subReason.descricao }}
      </option>
    </select>

    <div
      *ngIf="!!formGroup.get('subMotivo').value"
      class="replicate-indicator"
      (click)="emitReplicateColumnValue('subMotivo')"
    ></div>
  </td>

  <td class="relative-cell">
    <input
      matInput
      readonly
      [value]="formGroup.get('clienteResponsavel').value || 'Nenhum cliente responsável'"
    />
    <select formControlName="clienteResponsavel">
      <option value="">Nenhum cliente responsável</option>
      <option *ngFor="let client of (details?.clientesResponaveis || [])" [value]="client">{{ client }}</option>
    </select>
  </td>

  <td  class="relative-cell">
    <input
      matInput
      formControlName="justificativa"
      class="justification-field"
      (paste)="handlePastedValue('justificativa', $event)"
      placeholder="Escreva uma justificativa para ser enviada para aprovação"
    />

    <div
      *ngIf="!!formGroup.get('justificativa').value"
      class="replicate-indicator"
      (click)="emitReplicateColumnValue('justificativa')"
    ></div>
  </td>

</ng-container>
