import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/shared/base.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { MessageService } from 'src/app/core/services';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { PerfilPermissaoService } from 'src/app/core/services/perfil-permissao.service';
import { DiariaService } from 'src/app/core/services/diaria.service';
import { BreadCrumbItem } from 'src/app/core/models/bread-crumb-item.model';
import { DiariaManualApprovalRequest } from 'src/app/models/diarias/diaria-approval.interface';
import { DiariaDetail } from 'src/app/models/diarias/diaria-detail.interface';
import { createManualApprovalFormGroup } from '../utils/create-manual-approval-form-group';

@Component({
  selector: 'app-sinalizar-diaria',
  templateUrl: './sinalizar-diaria.component.html',
  styleUrls: ['./sinalizar-diaria.component.scss']
})
export class SinalizarDiariaComponent extends BaseComponent implements OnInit {
  isSubmitting = false;
  isLoadingDetails = false;
  formGroup: FormGroup;
  details: DiariaDetail | undefined;
  user: { preferred_username: string };
  @ViewChild('successDialog') successDialogTemplate: TemplateRef<any>;
  @ViewChild('errorDialog') errorDialogTemplate: TemplateRef<any>;

  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected menuService: MenuService,
    protected sessionStorageService: SessionStorageService,
    protected profilePermissionService: PerfilPermissaoService,
    private diariaService: DiariaService,
    private messageService: MessageService,
    private matDialog: MatDialog,
  ) {
    super(authService, router, menuService, sessionStorageService, profilePermissionService, 'diarias', '#F9FAFB');
    this.menuService.setBreadCrumb([
      new BreadCrumbItem('MENU.HOME'),
      new BreadCrumbItem('Diárias', '/diarias'),
      new BreadCrumbItem('Sinalizar Diária Manualmente', '/diarias/sinalizar'),
    ]);
  }

  ngOnInit(): void {
    super.checkAuth();
    this.formGroup = createManualApprovalFormGroup({ withTravelNumberAndPlate: true });
    this.authService.userData.subscribe(user => this.user = user);
  }

  navigateBack(): void {
    this.router.navigate(['diarias']);
  }

  async loadDiariaManual(): Promise<void> {
    const travelNumber = this.formGroup.get('numeroViagem').value;
    if (travelNumber.length < 5) {
      return;
    }

    try {
      this.isLoadingDetails = true;
      const logistic = this.profilePermissionService.getCurrentLogisticaRole().logistica;
      const details = await this.diariaService.getDetailForManualRequest(logistic, travelNumber);
      if (!details || !details.numeroViagem) {
        return this.messageService.error('Viagem não encontrada');
      }
      this.formGroup.get('placa').setValue(details.placa);
      this.details = details;
    } finally {
      this.isLoadingDetails = false;
    }
  }

  async submit(): Promise<void> {
    const manualApproval = this.buildManualApprovalRequestObject(this.formGroup);
    const responsibleAreaOptions = await this.diariaService.getAllResponsibleAreas();
    try {
      this.isSubmitting = true;
      const response = await this.diariaService.postManualApprovalRequest(manualApproval).toPromise();
      if (!response?.success) {
        return this.messageService.error(response?.messages?.[0].value || 'Ocorreu um erro ao sinalizar a diária');
      }
      const responsibleArea = responsibleAreaOptions.find(({ key }) => key === manualApproval.areaResponsavel);
      this.openSuccessDialog(responsibleArea?.descricao || manualApproval.areaResponsavel);
    } finally {
      this.isSubmitting = false;
    }
  }

  private openSuccessDialog(responsibleArea: string): void {
    const dialogRef = this.matDialog.open(this.successDialogTemplate, { width: '500px', data: { responsibleArea } });
    dialogRef.afterClosed().subscribe(() => this.navigateBack());
  }

  async submitApprovalRequestFormGrid(formArray: FormArray): Promise<void> {
    const values = formArray.controls as FormGroup[];
    const body = values.map(item => this.buildManualApprovalRequestObject(item));
    const response = await this.diariaService.postBatchManualApprovalRequest(body);
    if (response?.success) {
      this.messageService.success('Diárias sinalizadas com sucesso');
      return this.navigateBack();
    }
    const text = response?.messages?.[0].value;
    const title = 'Ocorreu um erro ao sinalizar as diárias';
    this.matDialog.open(this.errorDialogTemplate, { width: '500px', data: { title, text } });
  }

  private buildManualApprovalRequestObject(formGroup: FormGroup): DiariaManualApprovalRequest {
    return {
      logistica: this.perfilPermissaoService.getCurrentLogisticaRole().logistica,
      numeroViagem: formGroup.get('numeroViagem').value,
      placa: formGroup.get('placa').value,
      areaResponsavel: formGroup.get('areaResponsavel').value,
      motivo: formGroup.get('motivo').value,
      subMotivo: formGroup.get('subMotivo').value,
      clienteResponsavel: formGroup.get('clienteResponsavel').value,
      justificativa: formGroup.get('justificativa').value,
      idUsuarioLogado: this.user.preferred_username,
    };
  }
}
